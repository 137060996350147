<template>
  <div id="header-bar" :style="{backgroundColor: mobileDropMenuVisible ? 'rgb(255, 255, 255)' : 'rgba(255, 255, 255)'}" :class="{'header-hidden': !isMenuVisible}">
    <!--mobile-->
    <div class="wrapper hidden-sm-and-up">
      <div class="click-div" @click="handleHomeClick"></div>
      <el-menu id="mobile-menu" :default-active="onRoutes()"
               mode="horizontal"
               background-color="transparent"
               text-color="white" router>
        <el-dropdown trigger="click" @visible-change="mobileDropMenuShow" :hide-on-click="false" ref="dropdown">
          <el-menu-item :index="$router.path">
            <div v-if="!mobileDropMenuVisible" class="cracker-top" >
              <span></span>
            </div>
            <div  class="cracker-upper-middle" :class="{'crust-top': mobileDropMenuVisible}">
              <span></span>
            </div>
            <div  class="cracker-lower-middle" :class="{'crust-bottom': mobileDropMenuVisible}">
              <span></span>
            </div>
            <div v-if="!mobileDropMenuVisible" class="cracker-bottom" >
              <span></span>
            </div>
          </el-menu-item>
          <el-dropdown-menu class="mobile-dropdown" slot="dropdown">
            <router-link to="/">
              <el-dropdown-item class="main-menu" @click.native="closeDropdown">首页</el-dropdown-item>
            </router-link>
            <el-dropdown-item @click.native="toggleCoreMobileSubMenu" :divided="showCoreMobileSubMenu"  class="main-menu" :style="{ color: coreFontColor }">核心业务</el-dropdown-item>
            <router-link to="/jinMingFengJewelry"><el-dropdown-item v-if="showCoreMobileSubMenu" class="sub-menu" @click.native="closeDropdown">金明丰珠宝</el-dropdown-item></router-link>
            <router-link to="/midasRefining"><el-dropdown-item v-if="showCoreMobileSubMenu" class="sub-menu" @click.native="closeDropdown">点金精炼</el-dropdown-item></router-link>
            <router-link to="/midianNewMaterials"><el-dropdown-item v-if="showCoreMobileSubMenu" class="sub-menu" @click.native="closeDropdown">点金新材料</el-dropdown-item></router-link>

            <el-dropdown-item  @click.native="toggleServiceMobileSubMenu" :divided="showServiceMobileSubMenu"  class="main-menu" :style="{ color: serviceFontColor }">产品服务</el-dropdown-item>
            <router-link to="/jewelry"><el-dropdown-item v-if="showServiceMobileSubMenu" class="sub-menu" @click.native="closeDropdown">珠宝首饰</el-dropdown-item></router-link>
            <router-link to="/bullion"><el-dropdown-item v-if="showServiceMobileSubMenu" class="sub-menu" @click.native="closeDropdown">金锭、金条</el-dropdown-item></router-link>
            <router-link to="/materialProduct"><el-dropdown-item v-if="showServiceMobileSubMenu" class="sub-menu" @click.native="closeDropdown">贵金属材料产品</el-dropdown-item></router-link>
            <router-link to="/recyclingRefining"><el-dropdown-item v-if="showServiceMobileSubMenu" class="sub-menu" @click.native="closeDropdown">回收与精炼</el-dropdown-item></router-link>
            <router-link to="/analyze"><el-dropdown-item v-if="showServiceMobileSubMenu" class="sub-menu" @click.native="closeDropdown">分析服务</el-dropdown-item></router-link>

            <router-link to="/Announcement"><el-dropdown-item class="main-menu"  @click.native="closeDropdown">LBMA/LPPM专栏</el-dropdown-item></router-link>
            <el-dropdown-item @click.native="toggleCareerMobileSubMenu" :divided="showCareerMobileSubMenu"  class="main-menu" :style="{ color: careerFontColor }">职业生涯</el-dropdown-item>
            <router-link to="/talentPlan"><el-dropdown-item v-if="showCareerMobileSubMenu" class="sub-menu" @click.native="closeDropdown">人才计划</el-dropdown-item></router-link>
            <router-link to="/careerDevelopment"><el-dropdown-item v-if="showCareerMobileSubMenu" class="sub-menu" @click.native="closeDropdown">职业发展</el-dropdown-item></router-link>
            <router-link to="/employeeCare"><el-dropdown-item v-if="showCareerMobileSubMenu" class="sub-menu" @click.native="closeDropdown">员工关怀</el-dropdown-item></router-link>

            <el-dropdown-item class="main-menu" :style="{ color: aboutFontColor }" @click.native="toggleAboutMobileSubMenu" :divided="showAboutMobileSubMenu">关于我们</el-dropdown-item>
            <router-link to="/companyProfile"><el-dropdown-item v-if="showAboutMobileSubMenu" class="sub-menu" @click.native="closeDropdown">公司介绍</el-dropdown-item></router-link>
            <router-link to="/developmentPath"><el-dropdown-item v-if="showAboutMobileSubMenu" class="sub-menu" @click.native="closeDropdown">发展历程</el-dropdown-item></router-link>
            <router-link to="/honor"><el-dropdown-item v-if="showAboutMobileSubMenu" class="sub-menu" @click.native="closeDropdown">公司荣誉</el-dropdown-item></router-link>
            <router-link to="/corporateNews"><el-dropdown-item v-if="showAboutMobileSubMenu" class="sub-menu" @click.native="closeDropdown">企业动态</el-dropdown-item></router-link>
            <router-link to="/contactUs"><el-dropdown-item v-if="showAboutMobileSubMenu" class="sub-menu" @click.native="closeDropdown">联系我们</el-dropdown-item></router-link>

            <router-link to="/en/"><el-dropdown-item class="main-menu" @click.native="closeDropdownOther" style="height: 56px;"><img src="https://sfevip.oss-cn-hongkong.aliyuncs.com/assets/images/english.png" alt=""></el-dropdown-item></router-link>
          </el-dropdown-menu>
        </el-dropdown>
      </el-menu>
    </div>
    <!--pc-->
    <div class="wrapper hidden-xs-only" style="padding-left:10%; padding-right: 10%;">
      <el-menu style="padding: 0; width: 100%"
               :default-active="activeIndex"
               mode="horizontal"
               background-color="transparent"
               text-color="black">
        <router-link to="/">
          <el-menu-item index="/" style="padding: 0">
            <img src="https://sfevip.oss-cn-hongkong.aliyuncs.com/assets/images/pg-logo-cn.png" alt="" class="logo">
          </el-menu-item>
        </router-link>
        <div class="menu-items">
          <router-link to="/" class="menu-link">
            <el-menu-item index="/" style="margin-top:35px;font-size:16px;">首页</el-menu-item>
          </router-link>
          <el-menu-item index="/CoreBusiness" style="margin-top:35px;font-size:16px;" @click="toggleCoreSubMenu" ref="coreSubMenu">核心业务</el-menu-item>
          <el-menu-item index="/ProductService" style="margin-top:35px;font-size:16px;" @click="toggleServiceSubMenu" ref="serviceSubMenu">产品服务</el-menu-item>
          <router-link to="/Announcement" class="menu-link">
            <el-menu-item index="/Announcement" style="margin-top:35px;font-size:16px;">LBMA/LPPM专栏</el-menu-item>
          </router-link>
          <el-menu-item index="/Career" style="margin-top:35px;font-size:16px;" @click="toggleCareerSubMenu" ref="careerSubMenu">职业生涯</el-menu-item>
          <el-menu-item index="/About" style="margin-top:35px;font-size:16px;" @click="toggleAboutSubMenu" ref="aboutSubMenu">关于我们</el-menu-item>
          <el-menu-item index="/" style="margin-left: -80px;margin-top: -5px" @click="toggleHeaderBarVersion"><img src="https://sfevip.oss-cn-hongkong.aliyuncs.com/assets/images/english.png" alt="" style="margin-left:-8px;width: 70px"></el-menu-item>
        </div>
      </el-menu>
    </div>

    <div v-if="showCareerSubMenu" class="career-submenu"  ref="showCareerSubMenu">
      <div class="career-submenu-header"></div>
      <div>
        <div class="submenu-title-spec">
          <router-link to="" class="submenu-title">职业生涯</router-link>
        </div>
        <router-link to="/talentPlan" class="submenu-item">人才计划</router-link>
        <router-link to="/careerDevelopment" class="submenu-item">职业发展</router-link>
        <router-link to="/employeeCare" class="submenu-item">员工关怀</router-link>
      </div>
    </div>

    <div v-if="showAboutSubMenu" class="career-submenu"  ref="showAboutSubMenu">
      <div class="career-submenu-header"></div>
      <div>
        <div class="submenu-title-spec">
          <router-link to="" class="submenu-title">关于我们</router-link>
        </div>
        <router-link to="/companyProfile" class="submenu-item">公司介绍</router-link>
        <router-link to="/developmentPath" class="submenu-item">发展历程</router-link>
        <router-link to="/honor" class="submenu-item">公司荣誉</router-link>
        <router-link to="/corporateNews" class="submenu-item">企业动态</router-link>
        <router-link to="/contactUs" class="submenu-item">联系我们</router-link>
      </div>
    </div>

    <div v-if="showServiceSubMenu" class="career-submenu"  ref="showServiceSubMenu">
      <div class="career-submenu-header"></div>
      <div>
        <div class="submenu-title-spec">
          <router-link to="" class="submenu-title">产品服务</router-link>
        </div>
          <router-link to="/jewelry" class="submenu-item">珠宝首饰</router-link>
        <router-link to="/bullion" class="submenu-item">金锭、金条</router-link>
        <router-link to="/materialProduct" class="submenu-item">贵金属材料产品</router-link>
        <router-link to="/recyclingRefining" class="submenu-item">回收与精炼</router-link>
        <router-link to="/analyze" class="submenu-item">分析服务</router-link>
      </div>
    </div>

    <div v-if="showCoreSubMenu" class="career-submenu" ref="showCoreSubMenu">
      <div class="career-submenu-header"></div>
      <div>
        <div class="submenu-title-spec">
          <router-link to="" class="submenu-title">核心业务</router-link>
        </div>
        <router-link to="/jinMingFengJewelry" class="submenu-item" >金明丰珠宝</router-link>
        <router-link to="/midasRefining" class="submenu-item" >点金精炼</router-link>
        <router-link to="/midianNewMaterials" class="submenu-item">点金新材料</router-link>
      </div>
    </div>

  </div>
</template>

<script>
import {EventBus} from "../../assets/js/event-bus"

export default {
  name: 'HeaderBar',
  data() {
    return {
      mobileDropMenuVisible: false,
      showCareerSubMenu: false,
      showAboutSubMenu: false,
      showServiceSubMenu: false,
      showCoreSubMenu: false,
      defaultActive:false,
      activeMainMenu: undefined,
      activeIndex: '/',
      showCoreMobileSubMenu: false,
      showAboutMobileSubMenu: false,
      showServiceMobileSubMenu: false,
      showCareerMobileSubMenu: false,
      coreFontColor: 'black',
      aboutFontColor: 'black',
      serviceFontColor: 'black',
      careerFontColor: 'black',
      lastScrollTop: 0,
      isMenuVisible: true
    }
  },
  watch: {
    '$route'(to) {
      if (to.path === "/jewelry" || to.path === "/bullion" || to.path === "/materialProduct" || to.path === "/recyclingRefining" || to.path === "/analyze") {
        this.activeIndex = '/ProductService'
      }
      if (to.path === "/talentPlan" || to.path === "/careerDevelopment" || to.path === "/employeeCare") {
        this.activeIndex = '/Career'
      }
      if (to.path === "/companyProfile" || to.path === "/developmentPath" || to.path === "/honor" || to.path === "/corporateNews" || to.path === "/contactUs") {
        this.activeIndex = '/About'
      }
      if (to.path === "/jinMingFengJewelry" || to.path === "/midasRefining" || to.path === "/midianNewMaterials") {
        this.activeIndex = '/CoreBusiness'
      }
    },
  },
  mounted () {
    document.addEventListener('mouseup',this.handleDocumentMouseUp)
    EventBus.$on('closeAllSubMenus', this.closeAllSubMenus);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.handleDocumentMouseUp);
    EventBus.$off('closeAllSubMenus', this.closeAllSubMenus);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    onRoutes() {
      return '/'
    },
    mobileDropMenuShow(visible) {
      this.mobileDropMenuVisible = visible
    },
    toggleCareerSubMenu() {
      this.showCareerSubMenu = !this.showCareerSubMenu
    },
    toggleAboutSubMenu() {
      this.showAboutSubMenu = !this.showAboutSubMenu
    },
    toggleServiceSubMenu() {
      this.showServiceSubMenu = !this.showServiceSubMenu
    },
    toggleCoreSubMenu() {
      this.showCoreSubMenu = !this.showCoreSubMenu
    },
    toggleCoreMobileSubMenu() {
      this.showCoreMobileSubMenu = !this.showCoreMobileSubMenu
      this.showCareerMobileSubMenu= false
      this.showAboutMobileSubMenu = false
      this.showServiceMobileSubMenu = false
      this.toggleFontColor()
    },
    toggleAboutMobileSubMenu() {
      this.showAboutMobileSubMenu = !this.showAboutMobileSubMenu
      this.showCoreMobileSubMenu = false
      this.showCareerMobileSubMenu= false
      this.showServiceMobileSubMenu = false
      this.toggleFontColor()
    },
    toggleServiceMobileSubMenu() {
      this.showServiceMobileSubMenu = !this.showServiceMobileSubMenu
      this.showCoreMobileSubMenu = false
      this.showCareerMobileSubMenu= false
      this.showAboutMobileSubMenu = false
      this.toggleFontColor()
    },
    toggleCareerMobileSubMenu() {
      this.showCareerMobileSubMenu = !this.showCareerMobileSubMenu
      this.showCoreMobileSubMenu = false
      this.showAboutMobileSubMenu = false
      this.showServiceMobileSubMenu = false
      this.toggleFontColor()
    },
    closeDropdown() {
      this.$refs.dropdown.hide()
      this.showCoreMobileSubMenu = false
      this.showCareerMobileSubMenu= false
      this.showAboutMobileSubMenu = false
      this.showServiceMobileSubMenu = false
      this.toggleFontColor()
    },
    toggleFontColor() {
      if (this.showCoreMobileSubMenu) {
        this.coreFontColor = '#A7A7D2'
      } else {
        this.coreFontColor = 'black'
      }

      if (this.showAboutMobileSubMenu) {
        this.aboutFontColor = '#A7A7D2'
      } else {
        this.aboutFontColor = 'black'
      }

      if (this.showServiceMobileSubMenu) {
        this.serviceFontColor = '#A7A7D2'
      } else {
        this.serviceFontColor = 'black'
      }

      if (this.showCareerMobileSubMenu) {
        this.careerFontColor = '#A7A7D2'
      } else {
        this.careerFontColor = 'black'
      }
    },
    handleHomeClick() {
      const route = this.$router.resolve({ name: 'home'})
      if (route.resolved && route.resolved.name === 'home') {
        this.$router.push({ name: 'home'})
      } else {
        this.$router.push('/404')
      }
    },
    toggleHeaderBarVersion() {
      this.$cookies.set('language', 'en', 60 * 60 * 24 * 30)
      EventBus.$emit('updateLanguage', 'en')
      const route = this.$router.resolve({ name: 'homeEN'})
      if (route.resolved && route.resolved.name === 'homeEN') {
        this.$router.push({ name: 'homeEN'})
      } else {
        this.$router.push('/404')
      }
    },
    closeDropdownOther() {
      this.$cookies.set('language', 'en', 60 * 60 * 24 * 30)
      EventBus.$emit('updateLanguage', 'en')
      this.$refs.dropdown.hide()
      this.showCoreMobileSubMenu = false
      this.showCareerMobileSubMenu= false
      this.showAboutMobileSubMenu = false
      this.showServiceMobileSubMenu = false
      this.toggleFontColor()
    },
    handleDocumentMouseUp(e) {
      // const checkAndCloseSubMenu = (ref, subMenu, stateProperty) => {
      //   if (this.$refs[ref] && this.$refs[subMenu] && 
      //       !this.$refs[ref].contains(e.target) && 
      //       !this.$refs[subMenu].$el.contains(e.target)) {
      //     this[stateProperty] = false;
      //   }
      // };
      // checkAndCloseSubMenu('showCareerSubMenu', 'careerSubMenu', 'showCareerSubMenu');
      // checkAndCloseSubMenu('showAboutSubMenu', 'aboutSubMenu', 'showAboutSubMenu');
      // checkAndCloseSubMenu('showServiceSubMenu', 'serviceSubMenu', 'showServiceSubMenu');
      // checkAndCloseSubMenu('showCoreSubMenu', 'coreSubMenu', 'showCoreSubMenu');

      let showCareerSubMenu = this.$refs.showCareerSubMenu
      let careerSubMenu = this.$refs.careerSubMenu.$el
      if (showCareerSubMenu && careerSubMenu) {
        if (!showCareerSubMenu.contains(e.target) && !careerSubMenu.contains(e.target)) {
          this.showCareerSubMenu = false
        }
      }

      let showAboutSubMenu = this.$refs.showAboutSubMenu
      let aboutSubMenu = this.$refs.aboutSubMenu.$el
      if (showAboutSubMenu && aboutSubMenu) {
        if (!showAboutSubMenu.contains(e.target) && !aboutSubMenu.contains(e.target)) {
          this.showAboutSubMenu = false
        }
      }

      let showServiceSubMenu = this.$refs.showServiceSubMenu
      let serviceSubMenu = this.$refs.serviceSubMenu.$el
      if (showServiceSubMenu && serviceSubMenu) {
        if (!showServiceSubMenu.contains(e.target) && !serviceSubMenu.contains(e.target)) {
          this.showServiceSubMenu = false
        }
      }

      let showCoreSubMenu = this.$refs.showCoreSubMenu
      let coreSubMenu = this.$refs.coreSubMenu.$el
      if (showCoreSubMenu && coreSubMenu) {
        if (!showCoreSubMenu.contains(e.target) && !coreSubMenu.contains(e.target)) {
          this.showCoreSubMenu = false
        }
      }
    },
    closeAllSubMenus() {
      this.showCoreSubMenu = false;
      this.showCareerSubMenu = false;
      this.showAboutSubMenu = false;
      this.showServiceSubMenu = false;
      this.toggleFontColor();
    },
    handleSubMenuClick(path) {
      this.$router.push(path).then(() => {
        this.$nextTick(() => {
          this.closeAllSubMenus();
        });
      });
    },
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > this.lastScrollTop) {
        // 向下滚动
        this.isMenuVisible = false;
      } else {
        // 向上滚动
        this.isMenuVisible = true;
      }
      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // 避免负值
    }

  }
}
</script>

<style>
.el-popper {
  overflow: auto;
}
</style>
<style scoped lang="scss">

.header-hidden {
  top: -100px; /* 根据你的实际header高度调整 */
}
#header-bar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  font-weight: bold;
  transition: top 0.3s;
  @media only screen and (max-width: 767px) {
    height: 70px;
  }
  height: 80px;
  background: rgba(255,255,255,0.8);
  @media only screen and (min-width: 768px) {
    .logo {
      height: 80px;
      width: 230px;
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
      background-image: url('https://sfevip.oss-cn-hongkong.aliyuncs.com/assets/images/mobile-logo-cn.png');
      background-size: 100% auto;
      background-position: center center;
      height: auto;
    }
    .click-div {
      position: absolute;
      height: 50px;
      left: 20px;
      width: 170px;
    }
    #mobile-menu {
      margin-left: 80%;
      height: 70px;
      line-height: 48px;
      position: relative;
      &:first-child {
        width: 10px;
      }
      &:nth-child(2) {
        i {
          margin: 0;
        }
      }
      .cracker-top, .cracker-upper-middle, .cracker-lower-middle, .cracker-bottom {
        margin-left: -120%;
        position: absolute;
        text-align: center;
        width: 46px;
        height: 46px;
        line-height: 46px;
        -webkit-transition: transform 0.4s ease-out;
        -moz-transition: transform 0.4s ease-out;
        -ms-transition: transform 0.4s ease-out;
        -o-transition: transform 0.2s ease-out;
        transition: transform 0.2s ease-out;
        span {
          display: inline-block;
          width: 30px;
          height: 3px;
          background-color: white;
          position: relative;
          -webkit-transition: top 0.2s;
          -moz-transition: top 0.2s;
          -ms-transition: top 0.2s;
          -o-transition: top 0.2s;
          transition: top 0.2s;
        }
      }
      .cracker-top {
        top: -2px;
      }

      .cracker-upper-middle {
        top: 6px;
      }

      .cracker-lower-middle {
        top: 14px;
      }

      .cracker-bottom {
        top: 22px;
      }
      .crust-top {
        transform: rotate(45deg);
        top: 12px;
      }

      .crust-bottom {
        transform: rotate(-45deg);
        top: 12px;
      }
    }
    .menu-items {
      display: flex;
      justify-content: space-between;
    }
    .el-menu{
      height: 60px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      border: none;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0 20px;
      &:before,
      &:after {
        display: none;
      }
      .el-menu-item {
        height: 44px;
        line-height: 44px;
        border: none;
        background-color: transparent!important;
        padding: 0 10px;
        i {
          color: black;
          &:hover {
            color: black!important;
          }
        }
        &:hover {
          color: black!important;
        }
        &.is-active {
          color: #A7A7D2;
        }
      }
    }
  }
}
.mobile-dropdown {
  top: 70px!important;
  left: 0!important;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  border: none;
  margin: 0;
  border-radius: 0;
  width: 100%!important;
  height: auto;
  a {
    text-decoration: none;
  }
  & > .popper__arrow {
    display: none;
  }
  .el-dropdown-menu__item {
    height: 73px;
    font-size: 24px;
    line-height: 44px;
    background-color: transparent!important;
    &.el-dropdown-menu__item--divided {
      color: black;
      border: none;
      margin: 0 20px;
      padding-left: 0;
      border-bottom: 1px solid #D8D8D8;
      &::before {
        display: none;
      }
      &:nth-last-child(2) {
        border-bottom-color: black;
        font-weight: bold;
        i {
          left: 0;
          margin-right: 10px;
        }
      }
      &:hover {
        text-decoration: none;
        color: black;
      }
    }
  }

  .main-menu {
    font-size: 20px;
    font-weight: bolder;
    color: black;
    img {
      width: auto;
      height: 44%;
    }
  }
  .sub-menu {
    font-size: 18px;
    font-weight: 300;
  }
}

.career-submenu {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 1000;
  .career-submenu-header {
    height:4px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  }
  .submenu-title-spec {
    height: 40px;
    padding-left: 10%;
    border-bottom: 1px solid #ECEBEC;
    padding-top: 16px;
    .submenu-title {
      font-weight: 400;
      text-decoration: none;
      color: black;
    }
  }
  .submenu-item {
    padding-left: 10%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    display: block;
    color: black;
    text-decoration: none;
  }

  .submenu-item:hover {
    color: #A7A7D2;
    background-color: #ffffff;
  }
}

.menu-link {
  height: 44px;
  line-height: 44px;
  border: none;
  background-color: transparent!important;
  text-decoration: none;
}
</style>